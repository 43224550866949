
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import authService from "@/services/auth.service";

export default defineComponent({
  name: "profileCardOne",
  props: {
    widgetClasses: String,
  },
  setup() {
    const esContactoEditar = ref(false);
    const esNombreEditar = ref(false);
    const validarClaves = () => {
      if (model.value.clave === model.value.clave_confirm) {
        onGuardar({ clave: model.value.clave_confirm });
        Swal.fire("Excelente!", "Contraseña guardada con exito!", "success");
      } else {
        Swal.fire("Oops", "Las contraseñas deben coincidir", "question");
        document.getElementById("closeModal")?.click;
      }
    };
    const fetchInfo = () => {
      authService.me().then((data) => {
        console.log(data);
        model.value.movil = data.data.movil;
        model.value.razonSocial = data.data.razonSocial;
        model.value._id = data.data._id;
        model.value.nombre = data.data.nombre;
        model.value.correo = data.data.correo;
      });
    };
    const model = ref({
      razonSocial: "",
      correo: "",
      _id: "",
      movil: "",
      nombre: "",
      clave: "",
      clave_confirm: "",
    });
    const onGuardar = (info) => {
      authService.editarMe(info, model.value._id).then((data) => {
        console.log(data);
        model.value.movil = data.data.movil;
        esContactoEditar.value = false;
        esNombreEditar.value = false;
        fetchInfo();
      });
    };
    onMounted(() => {
      fetchInfo();
    });
    return {
      esContactoEditar,
      onGuardar,
      esNombreEditar,
      model,
      validarClaves,
    };
  },
});
