import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xxl-8" }
const _hoisted_2 = { class: "col-lg-8 col-sm-12" }
const _hoisted_3 = {
  class: "col-lg-4 col-sm-12",
  style: {"height":"100vh"}
}
const _hoisted_4 = { style: {"height":"68vh"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profileCardOne = _resolveComponent("profileCardOne")!
  const _component_adminLocations = _resolveComponent("adminLocations")!
  const _component_team = _resolveComponent("team")!
  const _component_invited = _resolveComponent("invited")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_profileCardOne, {
        "widget-classes": "mb-5 mb-xxl-8",
        style: {"height":"77vh"}
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_adminLocations, {
        class: "mb-3",
        "widget-classes": ""
      }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_team, { class: "mb-3 h-50 overflow-auto" }),
        _createVNode(_component_invited, { class: "mb-3 h-50" })
      ])
    ])
  ]))
}