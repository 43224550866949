
import { defineComponent, onMounted, ref } from "vue";
import profileCardOne from "@/components/widgets/feeds/ProfileCardOne.vue";
import adminLocations from "@/components/widgets/charts/AdminLocations.vue";
import invited from "@/components/widgets/invitados/Invited.vue";
import team from "@/components/widgets/grupos/GruposListarPerfil.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "overview",
  components: {
    profileCardOne,
    adminLocations,
    team,
    invited,
  },
  setup() {
    const model = ref({
      razonSocial: "",
      correo: "",
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Perfíl", ["Pages", "Profile"]);
      // axios
      //   .get("http://192.168.1.5:5051/api/suscripciones/auth/me", {
      //     headers: {
      //       Authorization:
      //         "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYTdhYzUyNjhlZjFmMjg3OTgzOWI1ZCIsImlhdCI6MTY0NjQ1NTMxMCwiZXhwIjoxNjQ2NTQxNzEwfQ.9sSrg4OxzGv6P_qudC4BbvRb6sHEnyUOPMWun0803b0",
      //     },
      //   })
      //   .then((data) => {
      //     console.log(data);
      //     model.value = data.data;
      //   });
    });
    return {
      model,
    };
  },
});
